// import * as rule from "@wlhy-web-lib/rule/esm";

export const getInvoiceRules = () => {
  const rules = {
    threshold: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "金额不能为空"
      },
      {
        validator: (_, value) => {
          let pattern = /^([0-9]+[d]*(.[0-9]{1,2})?)$/;
          if (!pattern.test(value)) {
            return Promise.reject(
              new Error("注：仅允许填写数字，最多两位小数")
            );
          } else {
            return Promise.resolve();
          }
        },
        type: "number",
        trigger: "blur"
      }
    ],
    content: [
      {
        required: true,
        trigger: "blur",
        message: "预警语不能为空"
      }
    ]
  };

  return rules;
};
