<template>
  <div class="content-box">
    <Form
      ref="formRef"
      :model="formState"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
      :rules="rules"
      class="form-box"
    >
      <!-- 税源地名称 -->
      <FormItem label="税源地名称" name="tax_source_code">
        <a-select
          v-model:value="formState.tax_source_code"
          placeholder="请选择"
          style="width:320px;"
        >
          <a-select-option
            v-for="item in dictionaries.tax_source_list"
            :key="item.value"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <!-- 关联税务局 -->
      <FormItem label="关联税务局" name="tax_bureau_code">
        <a-select
          v-model:value="formState.tax_bureau_code"
          placeholder="请选择"
          style="width:320px;"
        >
          <a-select-option
            v-for="item in taxBureau"
            :key="item.value"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <!-- 税务局数据标识 -->
      <FormItem label="税务局数据标识" name="data_flag">
        <a-select
          dropdownClassName="width-shot-option"
          v-model:value="formState.data_flag"
          placeholder="请选择"
          style="width:320px;"
        >
          <a-select-option
            v-for="item in dictionaries.data_flag_list"
            :key="item.value"
            :value="item.value"
            @mouseenter="handleMouseenter(item.value)"
          >
            <Popover placement="rightTop" trigger="hover">
              <template #content>
                <div class="pop-content" v-if="sameTaxBureaus.length">
                  <p
                    class="fz-12"
                    v-for="(data, index) in sameTaxBureaus"
                    :key="data.id"
                  >
                    {{ index + 1 + ". " + data.tax_bureau_name }}
                  </p>
                </div>
                <div v-else class="pop-none">
                  暂无数据相通的税务局
                </div>
              </template>
              <template #title>
                <span class="fz-12 pop-title">数据相同的税务局：</span>
              </template>
              {{ item.label }}
            </Popover>
          </a-select-option>
        </a-select>
      </FormItem>

      <!-- 是否需要反包税 -->
      <FormItem label="是否需要反包税" name="calc_type">
        <div class="clude">
          <a-select
            v-model:value="formState.calc_type"
            placeholder="请选择"
            style="width:320px;"
          >
            <a-select-option
              v-for="item in dictionaries.calc_type_list"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>

          <Tooltip placement="top">
            <template #title>
              <span class="tips">
                由于其他税费（个人所得税、附加 税费、印花税）的完税凭证抬头为
                司机个人，平台计算企业所得税时 无法进行税前列支，因此将其他税
                费纳入增值税的计税依据开具发票</span
              >
            </template>

            <Icon
              class="icon"
              name="iconxuanfuchutishi"
              height="16"
              width="16"
            ></Icon>
          </Tooltip>
        </div>
      </FormItem>
      <FormItem class="pt-24" :wrapper-col="{ span: 17, offset: 7 }">
        <a-button @click="handleCancel">取消</a-button>
        <a-button class="ml-16" type="primary" @click="handleSubmit"
          >下一步</a-button
        >
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { ref, watchEffect, toRaw } from "vue";
import { Form, Tooltip, Popover } from "ant-design-vue";
import { getInvoiceRules } from "./config";
import { useTaxBureauByDataFlagApi } from "@/apis/source";
import $await from "@/utils/await";

export default {
  props: {
    visible: { type: Boolean, default: false },
    dictionaries: { type: Object, default: () => {} }
  },
  components: { Form, FormItem: Form.Item, Tooltip, Popover },
  setup(props, ctx) {
    const taxBureauByDataFlagApi = useTaxBureauByDataFlagApi();
    const formRef = ref(null);

    // 表单信息
    const formState = ref({
      tax_source_code: props.dictionaries.tax_source_list[0].value || undefined,
      tax_bureau_code: "",
      data_flag: props.dictionaries.data_flag_list[0].value || undefined,
      calc_type: 1
    });

    // 如果localstorage中有缓存，则去缓存中的数据
    if (localStorage.getItem("stepJson1")) {
      formState.value = JSON.parse(localStorage.getItem("stepJson1"));
    }
    const taxBureau = ref([]);

    watchEffect(() => {
      let tax_bureau_list = [];
      props.dictionaries.tax_source_list.forEach(item => {
        if (item.value === formState?.value.tax_source_code) {
          tax_bureau_list = item.tax_bureau_list;
        }
      });
      // 关联税务局枚举列表
      taxBureau.value = tax_bureau_list;
      // 关联税务局默认第一项
      formState.value.tax_bureau_code = tax_bureau_list[0].value;
    });

    // 根据税务局数据标识查询相同的税务局信息列表
    const sameTaxBureaus = ref([]);

    const rules = getInvoiceRules(formState);

    // 下一步
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        localStorage.setItem("step", 2);
        localStorage.setItem(
          "stepJson1",
          JSON.stringify(toRaw(formState.value))
        );
        ctx.emit("handleNextStep");
      });
    };

    // 根据税务局标识获取相同税务局
    const handleMouseenter = async value => {
      const [, res] = await $await(
        taxBureauByDataFlagApi({
          data_flag: value,
          tax_bureau_code: formState.value.tax_bureau_code
        })
      );
      if (res) {
        sameTaxBureaus.value = res;
      }
    };

    // 取消
    const handleCancel = () => {
      ctx.emit("cancel");
    };
    return {
      formRef,
      rules,
      formState,
      taxBureau,
      sameTaxBureaus,
      handleMouseenter,
      handleSubmit,
      handleCancel,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };
  }
};
</script>

<style lang="less" scoped>
.content-box {
  width: 530px;
  padding: 32px 48px 0;
}

.clude {
  position: relative;
  .icon {
    position: absolute;
    top: 12px;
    right: 8px;
  }
}
.tips {
  width: 204px;
  font-size: 12px;
  display: block;
}
.pop-content {
  width: 280px;
  p {
    margin-bottom: 16px;
  }
}
.pop-none {
  height: 100%;
  padding: 0 50px;
  font-size: 12px;
  color: #c1c1c1;
  white-space: nowrap;
}
</style>
<style lang="less">
.width-shot-option {
  width: 74px !important;
  min-width: 74px !important;
}
</style>
