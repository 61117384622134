<template>
  <div class="content-box step9">
    <!-- 税源地信息 -->
    <p class="fz-16 fw-600">税源地信息</p>
    <div style="overflow:hidden;">
      <ul class="left">
        <li class="info">
          <p><span>税源地名称：</span>{{ previewInfo.tax_source_name }}</p>
        </li>
        <li class="info">
          <p><span>税务局数据标识： </span>{{ previewInfo.data_flag }}</p>
        </li>
      </ul>
      <ul class="right">
        <li class="info">
          <p><span>关联税务局： </span>{{ previewInfo.tax_bureau_name }}</p>
        </li>
        <li class="info">
          <p>
            <span>是否需要反包税计算： </span>{{ previewInfo.calc_type_name }}
          </p>
        </li>
      </ul>
    </div>

    <!-- 财税信息 -->
    <p class="fz-16 fw-600 mt-32 mb-16">财税信息</p>
    <!-- 列表 -->
    <vxe-table
      :data="tableData"
      show-overflow
      width="100%"
      :cell-class-name="cellClassName"
    >
      <vxe-table-column field="tax_code_name" title="税种" min-width="144">
      </vxe-table-column>
      <vxe-table-column field="tax_base_name" title="税基" min-width="168">
        <template v-slot="{ row }">
          <p
            class="line-height"
            v-for="(item, index) in row.tax_base_name.split(',')"
            :key="index"
          >
            {{ item }}
          </p>
        </template>
      </vxe-table-column>
      <vxe-table-column field="rate_name" title="税率" min-width="112">
        <template v-slot="{ row }">
          <p
            class="line-height"
            v-for="(item, index) in row.rate_name.split(',')"
            :key="index"
          >
            {{ item }}
          </p>
        </template>
      </vxe-table-column>
      <vxe-table-column field="reduction_name" title="惠普减免" min-width="128">
        <template v-slot="{ row }">
          <p
            class="line-height"
            v-for="(item, index) in row.reduction_name.split(',')"
            :key="index"
          >
            {{ item }}
          </p>
        </template>
      </vxe-table-column>
      <vxe-table-column
        field="actual_rate_name"
        title="实际税率"
        min-width="128"
      >
        <template v-slot="{ row }">
          <p
            class="line-height"
            v-for="(item, index) in row.actual_rate_name.split(',')"
            :key="index"
          >
            {{ item }}
          </p>
        </template>
      </vxe-table-column>
      <vxe-table-column field="threshold_name" title="起征点" min-width="112">
        <template v-slot="{ row }">
          <p
            class="line-height"
            v-for="(item, index) in row.threshold_name.split(',')"
            :key="index"
          >
            {{ item }}
          </p>
        </template>
      </vxe-table-column>
      <vxe-table-column
        field="threshold_period_name"
        title="汇算周期"
        min-width="122"
      >
        <template v-slot="{ row }">
          <p
            class="line-height"
            v-for="(item, index) in row.threshold_period_name.split(',')"
            :key="index"
          >
            {{ item }}
          </p>
        </template>
      </vxe-table-column>
      <vxe-table-column
        field="grading_desc"
        title="月平均销售额分档"
        min-width="148"
        v-if="show_grading_desc"
      >
        <template v-slot="{ row }">
          <div v-if="row.grading_desc">
            <p
              class="line-height"
              v-for="(item, index) in row.grading_desc.split(',')"
              :key="index"
            >
              {{ item }}
            </p>
          </div>
          <p v-else>-</p>
        </template>
      </vxe-table-column>
    </vxe-table>

    <!-- 网络货运预警 -->
    <p class="fz-16 fw-600 mt-32 mb-16">网络货运预警</p>
    <ul class="d-flex">
      <li class="info mr-80">
        <p>
          <span>汇算周期： </span
          >{{ previewInfo.preAlarmInfo?.calc_cycle_type_name }}
        </p>
      </li>
      <li class="info mr-80">
        <p>
          <span>预警不含增值税金额： </span
          >{{ previewInfo.preAlarmInfo?.threshold_name }}
        </p>
      </li>
      <li class="info">
        <p>
          <span>等级： </span>{{ previewInfo.preAlarmInfo?.alarm_level_name }}
        </p>
      </li>
    </ul>
    <ul>
      <li class="info">
        <p><span>预警语： </span>{{ previewInfo.preAlarmInfo?.content }}</p>
      </li>
    </ul>

    <p class="tips">请仔细核对信息内容后确定！</p>
    <div class="pt-16 d-flex justify-center">
      <a-button @click="handleCancel">上一步</a-button>
      <a-button
        class="ml-16"
        type="primary"
        @click="handleSubmit"
        :loading="isLoading"
        >确定</a-button
      >
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { useTaxSavePreviewApi, useTaxSourceSaveApi } from "@/apis/source";
import $await from "@/utils/await";

export default {
  props: {
    visible: { type: Boolean, default: false }
  },
  components: {},
  setup(_, ctx) {
    const router = useRouter();
    const taxSavePreviewApi = useTaxSavePreviewApi();
    const taxSourceSaveApi = useTaxSourceSaveApi();
    const previewInfo = ref({});
    const tableData = ref([]);
    const isLoading = ref(false);
    const show_grading_desc = ref(false);

    (async () => {
      const {
        calc_type,
        data_flag,
        tax_bureau_code,
        tax_source_code
      } = JSON.parse(localStorage.getItem("stepJson1"));
      const [, res] = await $await(
        taxSavePreviewApi({
          alarm_info: JSON.parse(localStorage.getItem("stepJson8")),
          calc_type,
          data_flag,
          tax_bureau_code,
          tax_source_code,
          tax_type_config_list: [
            JSON.parse(localStorage.getItem("stepJson2")),
            JSON.parse(localStorage.getItem("stepJson3")),
            JSON.parse(localStorage.getItem("stepJson4")),
            JSON.parse(localStorage.getItem("stepJson5")),
            JSON.parse(localStorage.getItem("stepJson6")),
            JSON.parse(localStorage.getItem("stepJson7"))
          ]
        })
      );
      if (res) {
        previewInfo.value = res;
        tableData.value = res.pre_tax_rate_config_list;

        // 判断是否显示「月平均销售额分档」列
        const row = tableData.value.find(
          item => item.tax_code_name === "个人所得税"
        );
        if (row.grading_desc) {
          show_grading_desc.value = true;
        } else {
          show_grading_desc.value = false;
        }
      }
    })();

    // 下一步
    const handleSubmit = async () => {
      isLoading.value = true;
      const {
        calc_type,
        data_flag,
        tax_bureau_code,
        tax_source_code
      } = JSON.parse(localStorage.getItem("stepJson1"));
      const [, res] = await $await(
        taxSourceSaveApi({
          alarm_info: JSON.parse(localStorage.getItem("stepJson8")),
          calc_type,
          data_flag,
          tax_bureau_code,
          tax_source_code,
          tax_type_config_list: [
            JSON.parse(localStorage.getItem("stepJson2")),
            JSON.parse(localStorage.getItem("stepJson3")),
            JSON.parse(localStorage.getItem("stepJson4")),
            JSON.parse(localStorage.getItem("stepJson5")),
            JSON.parse(localStorage.getItem("stepJson6")),
            JSON.parse(localStorage.getItem("stepJson7"))
          ]
        })
      );
      if (res) {
        isLoading.value = false;
        message.success("税源地已生成");
        // 清除缓存数据
        localStorage.removeItem("step");
        localStorage.removeItem("stepJson1");
        localStorage.removeItem("stepJson2");
        localStorage.removeItem("stepJson3");
        localStorage.removeItem("stepJson4");
        localStorage.removeItem("stepJson5");
        localStorage.removeItem("stepJson6");
        localStorage.removeItem("stepJson7");
        localStorage.removeItem("stepJson8");
        router.back();
      } else {
        isLoading.value = false;
      }
    };

    // 取消
    const handleCancel = () => {
      ctx.emit("cancel");
    };

    // 个人所得税样式修改
    const cellClassName = ({ row, column }) => {
      if (column.property === "tax_code_name") {
        if (
          row.tax_code_name === "个人所得税" &&
          row.tax_base_name.indexOf(",") != -1
        ) {
          return "vertical-align-top";
        }
      }
      return null;
    };
    return {
      isLoading,
      previewInfo,
      tableData,
      show_grading_desc,
      handleSubmit,
      handleCancel,
      cellClassName
    };
  }
};
</script>

<style lang="less" scoped>
.content-box {
  width: 100%;
  .info {
    margin-top: 16px;
    span {
      color: #808080;
    }
  }
  .left {
    float: left;
    margin-right: 64px;
    .info {
      width: 232px;
      margin-top: 16px;
      span {
        color: #808080;
      }
    }
  }
  .right {
    float: left;
  }
  .tips {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #ff9800;
    margin-top: 32px;
  }
  .line-height {
    line-height: 24px;
    &:nth-child(1) {
      margin-top: 4px;
    }
    &:nth-last-of-type(1) {
      margin-bottom: 4px;
    }
  }
}
</style>
<style lang="less">
.step9 {
  .vxe-cell {
    max-height: initial !important;
  }
  .vertical-align-top {
    vertical-align: top;
    padding-top: 4px;
  }
}
</style>
