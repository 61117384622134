// import * as rule from "@wlhy-web-lib/rule/esm";

export const getInvoiceRules = () => {
  const rules = {
    tax_code: [
      {
        type: "string",
        required: true,
        trigger: "blur",
        message: "税种不能为空"
      }
    ],
    tax_base: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "税基不能为空"
      }
    ],
    rate: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "税率不能为空"
      }
    ],
    reduction: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "普惠减免不能为空"
      }
    ],
    actual_rate: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "实际税率不能为空"
      }
    ],
    threshold: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "起征点不能为空"
      }
    ],
    threshold_period: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "汇算周期不能为空"
      }
    ]
  };

  return rules;
};
