import { createVNode } from "vue";
import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

export const useChangeThreshold = (value, formState) => {
  Modal.confirm({
    title: "是否确定更改汇算周期",
    icon: createVNode(ExclamationCircleOutlined),
    content:
      "是否确定汇算更改周期，一旦更改将会联动更改其他税种的汇算周期，请谨慎操作！",
    okText: "确认",
    cancelText: "取消",
    onOk() {
      localStorage.setItem("threshold_period", value);
    },
    onCancel() {
      localStorage.setItem(
        "threshold_period",
        +localStorage.getItem("threshold_period")
      );
      formState.value.threshold_period = +localStorage.getItem(
        "threshold_period"
      );
    }
  });
};
