<template>
  <div class="content-box">
    <Form
      ref="formRef"
      :model="formState"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
      :rules="rules"
      class="form-box"
    >
      <!-- 汇算周期 -->
      <FormItem label="汇算周期">
        <a-select
          v-model:value="formState.calc_cycle_type"
          placeholder="请选择"
          style="width:300px;"
        >
          <a-select-option
            :value="item.value"
            v-for="item in dictionaries.alarm_period_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <!-- 预警不含增值税金额 -->
      <FormItem label="预警不含增值税金额" name="threshold">
        <a-input-number
          v-model:value="formState.threshold"
          style="width:120px;"
          placeholder="请输入金额"
        /><span class="ml-8">万</span>
      </FormItem>

      <!-- 级别 -->
      <FormItem label="级别">
        <a-select
          v-model:value="formState.alarm_level"
          placeholder="请选择"
          style="width:300px;"
        >
          <a-select-option
            :value="item.value"
            v-for="item in dictionaries.alarm_level_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <!-- 预警语 -->
      <FormItem label="预警语" name="content">
        <a-textarea
          placeholder="请输入"
          showCount
          style="width:300px;"
          :maxlength="50"
          :autoSize="{ minRows: 3, maxRows: 3 }"
          v-model:value="formState.content"
        />
      </FormItem>

      <FormItem class="pt-24" :wrapper-col="{ span: 17, offset: 7 }">
        <a-button @click="handleCancel">上一步</a-button>
        <a-button class="ml-16" type="primary" @click="handleSubmit"
          >完成</a-button
        >
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { ref, toRaw } from "vue";
import { Form } from "ant-design-vue";
import { getInvoiceRules } from "./config";

export default {
  props: {
    visible: { type: Boolean, default: false },
    dictionaries: { type: Object, default: () => {} }
  },
  components: { Form, FormItem: Form.Item },
  setup(_, ctx) {
    const formRef = ref(null);

    // 表单信息
    const formState = ref({
      // 汇算周期
      calc_cycle_type: 0,
      // 预警不含增值税金额
      threshold: "",
      // 等级
      alarm_level: 1,
      // 预警语
      content: ""
    });
    const rules = getInvoiceRules(formState);

    // 如果localstorage中有缓存，则去缓存中的数据
    if (localStorage.getItem("stepJson8")) {
      formState.value = JSON.parse(localStorage.getItem("stepJson8"));
      formState.value.threshold = formState.value.threshold / 10000;
    }

    // 下一步
    const handleSubmit = () => {
      formState.value.threshold = formState.value.threshold * 10000;
      formRef.value.validate().then(async () => {
        localStorage.setItem("step", 9);
        localStorage.setItem(
          "stepJson8",
          JSON.stringify(toRaw(formState.value))
        );
        ctx.emit("handleNextStep");
      });
    };

    // 取消
    const handleCancel = () => {
      ctx.emit("cancel");
    };
    return {
      formRef,
      rules,
      formState,
      handleSubmit,
      handleCancel,
      labelCol: { span: 9 },
      wrapperCol: { span: 15 }
    };
  }
};
</script>

<style lang="less" scoped>
.content-box {
  width: 530px;
  padding: 32px 48px 0;
}

.clude {
  position: relative;
  .icon {
    position: absolute;
    top: 12px;
    right: 36px;
  }
}
.tips {
  width: 204px;
  font-size: 12px;
  display: block;
}
.pop-content {
  width: 288px;
  font-size: 12px;
  .content {
    margin-bottom: 16px;
  }
  .left,
  .right {
    float: left;
    background: #f8fafc;
    width: 144px;
    li,
    p {
      height: 32px;
      line-height: 32px;
      text-align: center;

      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;
    }
  }
}
</style>
<style lang="less">
.width-shot-option {
  width: 74px !important;
  min-width: 74px !important;
}
</style>
