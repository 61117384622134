<template>
  <div class="content-box">
    <Form
      ref="formRef"
      :model="formState"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
      :rules="rules"
      class="form-box"
    >
      <!-- 税种 -->
      <FormItem label="税种" name="tax_code">
        <p class="input">城市建设税</p>
      </FormItem>

      <!-- 税基 -->
      <FormItem label="税基" name="tax_base">
        <p v-if="tax_base_list.length === 1" class="input">
          {{ tax_base_list[0].label }}
        </p>
        <a-select
          v-if="tax_base_list.length && tax_base_list.length > 1"
          v-model:value="formState.tax_base"
          placeholder="请选择"
          style="width:320px;"
        >
          <a-select-option
            :value="item.value"
            v-for="item in tax_base_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <!-- 税率 -->
      <FormItem label="税率" name="rate">
        <a-select
          v-model:value="formState.rate"
          placeholder="请选择"
          style="width:320px;"
        >
          <a-select-option
            :value="item.value"
            v-for="item in rate_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <!-- 普惠减免 -->
      <FormItem label="普惠减免" name="reduction">
        <a-select
          v-model:value="formState.reduction"
          placeholder="请选择"
          style="width:320px;"
        >
          <a-select-option
            :value="item.value"
            v-for="item in reduction_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <!-- 实际税率 -->
      <FormItem label="实际税率" name="actual_rate">
        <p class="input">
          {{ parseFloat((formState.actual_rate * 100).toFixed(1)) + "%" }}
        </p>
      </FormItem>

      <!-- 起征点 -->
      <FormItem label="起征点" name="threshold">
        <a-select
          v-model:value="formState.threshold"
          placeholder="请选择"
          style="width:320px;"
        >
          <a-select-option
            :value="item.value"
            v-for="item in threshold_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <!-- 汇算周期 -->
      <FormItem label="汇算周期" name="threshold_period">
        <a-select
          v-model:value="formState.threshold_period"
          placeholder="请选择"
          style="width:320px;"
          @select="handleChangeThreshold"
        >
          <a-select-option
            :value="item.value"
            v-for="item in period_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <FormItem class="pt-24" :wrapper-col="{ span: 17, offset: 7 }">
        <a-button @click="handleCancel">上一步</a-button>
        <a-button class="ml-16" type="primary" @click="handleSubmit"
          >下一步</a-button
        >
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { ref, computed, unref } from "vue";
import { Form } from "ant-design-vue";
import { getInvoiceRules } from "./config";
import { useChangeThreshold } from "../../config";

export default {
  props: {
    visible: { type: Boolean, default: false },
    dictionaries: { type: Object, default: () => {} }
  },
  components: { Form, FormItem: Form.Item },
  setup(props, ctx) {
    const formRef = ref(null);

    // 获取该税种配置项
    let configuration = props.dictionaries.tax_type_config_list.find(
      tax => tax.tax_code === "10109"
    );
    // 税基
    let tax_base_list = configuration.tax_base_list;
    // 税率
    let rate_list = configuration.rate_list;
    // 普惠减免
    let reduction_list = configuration.reduction_list;
    // 起征点
    let threshold_list = configuration.threshold_list;
    // 汇算周期
    let period_list = configuration.period_list;

    !localStorage.getItem("threshold_period") &&
      localStorage.setItem("threshold_period", 0);

    // 表单信息
    const formState = ref({
      // 税种
      tax_code: "10109",
      // 税基
      tax_base: tax_base_list[0].value,
      // 税率
      rate: 0.07,
      // 普惠减免
      reduction: 0.5,
      // 实际税率
      actual_rate: computed(
        () =>
          formState.value.rate -
          formState.value.rate * formState.value.reduction
      ),
      // 起征点
      threshold: 100000,
      // 汇算周期
      threshold_period: +localStorage.getItem("threshold_period")
    });
    const rules = getInvoiceRules(formState);

    // 如果localstorage中有缓存，则去缓存中的数据
    if (localStorage.getItem("stepJson4")) {
      formState.value = JSON.parse(localStorage.getItem("stepJson4"));
      formState.value.threshold_period = +localStorage.getItem(
        "threshold_period"
      );
      formState.value.actual_rate = computed(
        () =>
          formState.value.rate -
          formState.value.rate * formState.value.reduction
      );
    }

    // 下一步
    const handleSubmit = () => {
      const data = unref(formState);
      formRef.value.validate().then(async () => {
        localStorage.setItem("step", 5);
        localStorage.setItem("stepJson4", JSON.stringify({ ...data }));
        ctx.emit("handleNextStep");
      });
    };

    // 上一步/取消
    const handleCancel = () => {
      ctx.emit("cancel");
    };

    // 更改汇算周期
    const handleChangeThreshold = value => {
      useChangeThreshold(value, formState);
    };
    return {
      formRef,
      rules,
      formState,
      handleChangeThreshold,
      handleSubmit,
      handleCancel,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      configuration,
      tax_base_list,
      rate_list,
      reduction_list,
      threshold_list,
      period_list
    };
  }
};
</script>

<style lang="less" scoped>
.content-box {
  width: 530px;
  padding: 32px 48px 0;
}
.input {
  background: #f5f5f5;
  border-radius: 4px;
  padding-left: 8px;
  height: 32px;
  width: 320px;
  line-height: 32px;
}

.clude {
  position: relative;
  .icon {
    position: absolute;
    top: 12px;
    right: 36px;
  }
}
.tips {
  width: 204px;
  font-size: 12px;
  display: block;
}
.pop-content {
  width: 288px;
  font-size: 12px;
  .content {
    margin-bottom: 16px;
  }
  .left,
  .right {
    float: left;
    background: #f8fafc;
    width: 144px;
    li,
    p {
      height: 32px;
      line-height: 32px;
      text-align: center;

      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;
    }
  }
}
</style>
<style lang="less">
.width-shot-option {
  width: 74px !important;
  min-width: 74px !important;
}
</style>
