<template>
  <div class="page-main-box" id="addTaxSource">
    <div class="title">
      <a
        ><Icon name="icontianjiashuiyuandiguanbi" @click="$router.back()"></Icon
      ></a>
      <span class="fz-16 ml-16 fw-600">添加税源地</span>
    </div>

    <div class="content mt-16">
      <div class="schedule">
        <div class="left">
          <div class="line"></div>
          <div
            class="blue-line"
            :style="{ height: BlueLineHeight[step] }"
          ></div>
          <Icon class="icon" name="icon1" width="12" height="12"> </Icon>
          <Icon
            class="icon mt-50"
            :name="step >= 2 ? 'icon1' : 'icon2'"
            width="12"
            height="12"
          ></Icon>
          <Icon
            class="icon mt-28"
            :name="step >= 2 ? 'icon4' : 'icon3'"
            width="6"
            height="6"
          ></Icon>
          <Icon
            class="icon mt-24"
            :name="step >= 3 ? 'icon4' : 'icon3'"
            width="6"
            height="6"
          ></Icon>
          <Icon
            class="icon mt-24"
            :name="step >= 4 ? 'icon4' : 'icon3'"
            width="6"
            height="6"
          ></Icon>
          <Icon
            class="icon mt-24"
            :name="step >= 5 ? 'icon4' : 'icon3'"
            width="6"
            height="6"
          ></Icon>
          <Icon
            class="icon mt-24"
            :name="step >= 6 ? 'icon4' : 'icon3'"
            width="6"
            height="6"
          ></Icon>
          <Icon
            class="icon mt-24"
            :name="step >= 7 ? 'icon4' : 'icon3'"
            width="6"
            height="6"
          ></Icon>
          <Icon
            class="icon mt-54"
            :name="step >= 8 ? 'icon1' : 'icon2'"
            width="12"
            height="12"
          ></Icon>
          <Icon
            class="icon mt-50"
            :name="step >= 9 ? 'icon1' : 'icon2'"
            width="12"
            height="12"
          ></Icon>
        </div>
        <div class="right">
          <p class="">税源地信息</p>
          <p class="mt-40">财税信息</p>
          <p class="second mt-16">增值税</p>
          <p class="second mt-8">个人所得税</p>
          <p class="second mt-8">城市维护建设税</p>
          <p class="second mt-8">教育费附加</p>
          <p class="second mt-8">地方教育费附加</p>
          <p class="second mt-8">印花税</p>
          <p class="mt-40">网络货运预警</p>
          <p class="mt-40">信息确认</p>
        </div>
      </div>

      <div class="form">
        <component
          v-if="dictionaries.tax_source_list"
          :is="Components"
          :dictionaries="dictionaries"
          @handleNextStep="handleNextStep"
          @cancel="handleCancel"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {} from "ant-design-vue";
import { BlueLineHeight } from "./Constants";
import Component1 from "./components/Step1";
import Component2 from "./components/Step2";
import Component3 from "./components/Step3";
import Component4 from "./components/Step4";
import Component5 from "./components/Step5";
import Component6 from "./components/Step6";
import Component7 from "./components/Step7";
import Component8 from "./components/Step8";
import Component9 from "./components/Step9";

export default {
  name: "add-tax-source",
  components: {
    Component1,
    Component2,
    Component3,
    Component4,
    Component5,
    Component6,
    Component7,
    Component8,
    Component9
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const step = ref(localStorage.getItem("step") || 1);
    const dictionaries = ref({});

    // 获取字典枚举
    store.dispatch("dictionaries/getDictionaries").then(res => {
      dictionaries.value = res;
    });

    // 下一步
    const handleNextStep = () => {
      step.value++;
    };

    // 上一步/取消
    const handleCancel = () => {
      if (step.value > 1) {
        step.value--;
      } else {
        router.back();
      }
    };

    const Components = computed(() => `Component${step.value}`);
    return {
      Components,
      step,
      BlueLineHeight,
      dictionaries,
      handleNextStep,
      handleCancel
    };
  }
};
</script>

<style lang="less" scoped>
#addTaxSource {
  background: #f4f4f4;
  padding: 16px 40px;
  .title {
    display: flex;
    align-items: center;
  }
  .content {
    border-radius: 5px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    min-height: 642px;
    background: #fff;
    position: relative;
    .schedule {
      width: 200px;
      height: 100%;
      border-right: 1px solid #d4d4d4;
      padding: 32px 24px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      .left {
        float: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-top: 4px;
        .line {
          height: 368px;
          width: 2px;
          background: #d4d4d4;
          position: absolute;
          top: 6px;
        }
        .blue-line {
          width: 2px;
          background: #007aff;
          position: absolute;
          top: 6px;
        }
        .icon {
          background: #fff;
          z-index: 1;
        }
      }
      .right {
        margin-left: 16px;
        float: left;
        .second {
          font-size: 12px;
          height: 22px;
          line-height: 22px;
          color: #808080;
        }
      }
    }
    .form {
      padding: 24px 24px 24px 224px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
