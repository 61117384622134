<template>
  <div class="content-box">
    <Form
      ref="formRef"
      :model="formState"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
      :rules="rules"
      class="form-box"
    >
      <!-- 税种 -->
      <FormItem label="税种" name="tax_code">
        <p class="input">个人所得税</p>
      </FormItem>

      <!-- 税基 -->
      <FormItem label="税基" name="tax_base">
        <p v-if="tax_base_list.length === 1" class="input">
          {{ tax_base_list[0].label }}
        </p>
        <a-select
          v-if="tax_base_list.length && tax_base_list.length > 1"
          v-model:value="formState.tax_base"
          placeholder="请选择"
          style="width:320px;"
        >
          <a-select-option
            :value="item.value"
            v-for="item in tax_base_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <!-- 纳税方式 -->
      <FormItem label="纳税方式" name="tax_payment_way">
        <a-select
          v-model:value="formState.tax_payment_way"
          placeholder="请选择"
          style="width:320px;"
        >
          <a-select-option
            :value="item.value"
            v-for="item in payment_way_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <!-- 征收方式 -->
      <FormItem
        label="征收方式"
        name="tax_levy_way"
        v-if="formState.tax_payment_way === 2"
      >
        <div class="clude">
          <a-select
            v-model:value="formState.tax_levy_way"
            placeholder="请选择"
            style="width:320px;"
          >
            <a-select-option
              :value="item.value"
              v-for="item in levy_way_list"
              :key="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
          <Popover
            placement="rightTop"
            trigger="hover"
            v-if="formState.tax_levy_way === 2"
          >
            <template #content>
              <div class="pop-content">
                <p class="fz-12 content">
                  月平均销售额：全年应纳税所得额分档/应税所得率/12
                </p>
                <ul class="left">
                  <p>经营所得使用税率</p>
                  <li>5%</li>
                  <li>10%</li>
                  <li>20%</li>
                  <li>30%</li>
                  <li>35%</li>
                </ul>
                <ul class="right">
                  <p>全年应纳税所得额分</p>
                  <li>3万及以内</li>
                  <li>3万至9万</li>
                  <li>9万至30万</li>
                  <li>30万至50万</li>
                  <li>50万以上</li>
                </ul>
              </div>
            </template>
            <Icon
              class="icon"
              name="iconxuanfuchutishi"
              height="16"
              width="16"
            ></Icon>
          </Popover>
        </div>
      </FormItem>

      <!-- 税率 -->
      <FormItem label="税率" name="rate" v-if="formState.tax_payment_way === 1">
        <a-select
          v-model:value="formState.rate"
          placeholder="请选择"
          style="width:320px;"
        >
          <a-select-option
            :value="item.value"
            v-for="item in rate_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <!-- 核定征收率 -->
      <FormItem
        label="核定征收率"
        name="deemed_levy_rate"
        v-if="formState.tax_payment_way === 2 && formState.tax_levy_way === 1"
      >
        <a-select
          v-model:value="formState.deemed_levy_rate"
          placeholder="请选择"
          style="width:320px;"
        >
          <a-select-option
            :value="item.value"
            v-for="item in deemed_levy_rate_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <!-- 应税所得率 -->
      <FormItem
        label="应税所得率"
        name="taxable_income_rate"
        v-if="formState.tax_payment_way === 2 && formState.tax_levy_way === 2"
      >
        <a-select
          v-model:value="formState.taxable_income_rate"
          placeholder="请选择"
          style="width:320px;"
        >
          <a-select-option
            :value="item.value"
            v-for="item in taxable_income_rate_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <!-- 普惠减免 -->
      <FormItem label="普惠减免" name="reduction">
        <a-select
          v-model:value="formState.reduction"
          placeholder="请选择"
          style="width:320px;"
        >
          <a-select-option
            :value="item.value"
            v-for="item in reduction_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <!-- 实际税率 -->
      <FormItem
        label="实际税率"
        name="actual_rate"
        v-if="
          !(formState.tax_payment_way === 2 && formState.tax_levy_way === 2)
        "
      >
        <a-select
          v-if="formState.reduction === -1"
          v-model:value="formState.actual_rate"
          placeholder="请选择"
          style="width:320px;"
        >
          <a-select-option
            :value="item.value"
            v-for="item in actual_rate_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
        <p v-else class="input">
          {{ parseFloat((formState.actual_rate * 100).toFixed(1)) + "%" }}
        </p>
      </FormItem>

      <!-- 起征点 -->
      <FormItem label="起征点" name="threshold">
        <a-select
          v-model:value="formState.threshold"
          placeholder="请选择"
          style="width:320px;"
        >
          <a-select-option
            :value="item.value"
            v-for="item in threshold_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <!-- 汇算周期 -->
      <FormItem label="汇算周期" name="threshold_period">
        <a-select
          v-model:value="formState.threshold_period"
          placeholder="请选择"
          style="width:320px;"
          @select="handleChangeThreshold"
        >
          <a-select-option
            :value="item.value"
            v-for="item in period_list"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </FormItem>

      <FormItem class="pt-24" :wrapper-col="{ span: 17, offset: 7 }">
        <a-button @click="handleCancel">上一步</a-button>
        <a-button class="ml-16" type="primary" @click="handleSubmit"
          >下一步</a-button
        >
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { ref, unref, watchEffect } from "vue";
import { Form, Popover } from "ant-design-vue";
import { getInvoiceRules } from "./config";
import { useChangeThreshold } from "../../config";

export default {
  props: {
    visible: { type: Boolean, default: false },
    dictionaries: { type: Object, default: () => {} }
  },
  components: { Form, FormItem: Form.Item, Popover },
  setup(props, ctx) {
    const formRef = ref(null);

    // 获取该税种配置项
    let configuration = props.dictionaries.tax_type_config_list.find(
      tax => tax.tax_code === "10106"
    );
    // 税基
    let tax_base_list = configuration.tax_base_list;
    // 税率
    let rate_list = configuration.rate_list;
    // 核定征收率
    let deemed_levy_rate_list = configuration.deemed_levy_rate_list;
    // 应税所得率
    let taxable_income_rate_list = configuration.taxable_income_rate_list;
    // 纳税方式
    let payment_way_list = configuration.payment_way_list;
    // 征收方式
    let levy_way_list = configuration.levy_way_list;
    // 普惠减免
    let reduction_list = configuration.reduction_list;
    // 起征点
    let threshold_list = configuration.threshold_list;
    // 汇算周期
    let period_list = configuration.period_list;
    // 实际税率
    let actual_rate_list = ref([]);

    !localStorage.getItem("threshold_period") &&
      localStorage.setItem("threshold_period", 0);

    // 表单信息
    const formState = ref({
      // 税种
      tax_code: "10106",
      // 税基
      tax_base: tax_base_list[0].value,
      // 纳税方式
      tax_payment_way: 1,
      // 征收方式
      tax_levy_way: 1,
      // 税率
      rate: 0.015,
      // 核定征收率
      deemed_levy_rate: 0.006,
      // 应税所得率
      taxable_income_rate: undefined,
      // 普惠减免
      reduction: 0,
      // 实际税率
      actual_rate: "",
      // 起征点
      threshold: 0,
      // 汇算周期
      threshold_period: +localStorage.getItem("threshold_period")
    });
    const rules = getInvoiceRules(formState);

    // 如果localstorage中有缓存，则去缓存中的数据
    if (localStorage.getItem("stepJson3")) {
      formState.value = JSON.parse(localStorage.getItem("stepJson3"));
      formState.value.threshold_period = +localStorage.getItem(
        "threshold_period"
      );
    }

    watchEffect(() => {
      // 预征
      if (formState.value.tax_payment_way === 1) {
        // 无普惠减免
        if (formState.value.reduction === -1) {
          actual_rate_list.value =
            props.dictionaries.pit_advance_levy_actual_rates;
          formState.value.actual_rate = 0.005;
        } else {
          // 有普惠减免
          formState.value.actual_rate =
            formState.value.rate -
            formState.value.rate * formState.value.reduction;
        }
      } else {
        // 核定征收
        if (formState.value.reduction === -1) {
          // 无普惠减免
          actual_rate_list.value =
            props.dictionaries.pit_deemed_levy_actual_rates;
          formState.value.actual_rate = 0.006;
        } else {
          // 有普惠减免
          formState.value.actual_rate =
            formState.value.deemed_levy_rate -
            formState.value.deemed_levy_rate * formState.value.reduction;
        }
      }
    });

    // 下一步
    const handleSubmit = () => {
      const data = unref(formState);
      formRef.value.validate().then(async () => {
        localStorage.setItem("step", 4);
        localStorage.setItem("stepJson3", JSON.stringify({ ...data }));
        ctx.emit("handleNextStep");
      });
    };

    // 上一步/取消
    const handleCancel = () => {
      ctx.emit("cancel");
    };

    // 更改汇算周期
    const handleChangeThreshold = value => {
      useChangeThreshold(value, formState);
    };

    return {
      formRef,
      rules,
      formState,
      handleChangeThreshold,
      handleSubmit,
      handleCancel,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      configuration,
      tax_base_list,
      rate_list,
      deemed_levy_rate_list,
      taxable_income_rate_list,
      reduction_list,
      threshold_list,
      period_list,
      payment_way_list,
      levy_way_list,
      actual_rate_list
    };
  }
};
</script>

<style lang="less" scoped>
.content-box {
  width: 530px;
  padding: 32px 48px 0;
}
.input {
  background: #f5f5f5;
  border-radius: 4px;
  padding-left: 8px;
  height: 32px;
  width: 320px;
  line-height: 32px;
}

.clude {
  position: relative;
  .icon {
    position: absolute;
    top: 12px;
    right: 36px;
  }
}
.tips {
  width: 204px;
  font-size: 12px;
  display: block;
}
.pop-content {
  width: 288px;
  font-size: 12px;
  .content {
    margin-bottom: 16px;
  }
  .left,
  .right {
    float: left;
    background: #f8fafc;
    width: 144px;
    li,
    p {
      height: 32px;
      line-height: 32px;
      text-align: center;

      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;
    }
  }
}
</style>
<style lang="less">
.width-shot-option {
  width: 74px !important;
  min-width: 74px !important;
}
</style>
